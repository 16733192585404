import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// import async from 'react-select/dist/declarations/src/async';

const urlApi = '/pre_orders';

const status = [
    { value: 'created', label: 'Створено клієнтом' },
    { value: 'aproved', label: 'Підтверджено' },
    { value: 'comlated', label: 'Виконано' }
]
const paymentMethod = [
    // { value: 'paid', label: 'Оплачено' },
    // { value: 'before_payment', label: 'Перед оплата' },
    { value: 'cash', label: 'Готівка' },
    { value: 'bank', label: 'Банківський переказ' },
    { value: 'bank', label: 'Мульти оплата' }
]
const curency = [
    // { value: 'paid', label: 'Оплачено' },
    // { value: 'before_payment', label: 'Перед оплата' },
    { value: 'uah', label: 'uah' },
    { value: 'usd', label: 'usd' },
    { value: 'eur', label: 'eur' }
]

const objOrderData = {
    account: '',
    status: '',
    description: '',
    dateOrder: null,
    curency: '',
    paymentMethod: '',
    sumOrdered: 0,
    curencyRate: 0,
    discountTotal: 0,
    discountPercent: 0,
    shippingAddressStreet: '',
    shippingAddressCity: '',
    shippingAddressBuilding: '',
    shippingAddressCountry: '',
    shippingAddressDate: '',
    synced: false
};



const ViewOrders = (props) => {
    // console.log('ViewOrders props', props);

    const data = props.data;
    const date = new Date(data.dateEntered);
    const dateEntered = date.toISOString().slice(0, 10);

    // Отримувач доставки
    const [shippingReceiverFirstName, setShippingReceiverFirstName] = useState(data?.shippingReceiver?.[0]?.firstName || "");
    const [shippingReceiverLastName, setShippingReceiverLastName] = useState(data?.shippingReceiver?.[0]?.lastName || "");
    const [shippingReceiverPhone, setShippingReceiverPhone] = useState(data?.shippingReceiver?.[0]?.phone || "");

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" colSpan={3}>
                                {(data.account != undefined) ?
                                    <p className='m-0'>Контрагент: <strong>{data.account.name}</strong></p>
                                    :
                                    <p className='m-0'>Контрагент: <strong className='text-danger'>Невказано</strong></p>
                                }
                                {(data.manager != undefined) ?
                                    <p className='m-0'>Менеджер: <strong>{(data.manager != undefined) ? `${data.manager.firstName} ${data.manager.lastName}` : ''}</strong></p>
                                    :
                                    <p className='m-0'>Менеджер: <strong className='text-danger'>Невказано</strong></p>

                                }
                            </TableCell>
                            <TableCell align="right">
                                <p className='m-0'>дата створення: <strong>{dateEntered}</strong></p>
                                <p className='m-0'>синхронізовано в 1с: <strong>{(data.synced) ? 'Так' : 'Ні'}</strong></p>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Статус:</TableCell>
                            <TableCell align="left"><strong>{
                                status.map((v, k) => {
                                    if (data.status == v.value)
                                        return v.label;
                                })
                            }</strong>
                            </TableCell>
                            {/* <TableCell align="right">дата виконання:</TableCell>
                            <TableCell align="left"><strong>{data.dateOrder}</strong></TableCell> */}
                            <TableCell align="right">Спосіб доставки:</TableCell>
                            <TableCell align="left"><strong>{data.shippingMethod}</strong></TableCell>
                        </TableRow>
                        <TableRow>
                            {/* <TableCell>Адреса:</TableCell> */}
                            <TableCell>Населений пункт:</TableCell>
                            {/* <TableCell align="left" colSpan={3}>{`${data.shippingAddressCountry} ${data.shippingAddressCity} ${data.shippingAddressStreet} ${data.shippingAddressBuilding}`}</TableCell> */}
                            <TableCell align="left"><strong>{data.shippingAddressCity}</strong></TableCell>
                            <TableCell align="right">{(data.shippingMethod === "Доставка на відділення Нової Пошти" || "Доставка на відділення Укрпошти") ? "Відділення" : "Вулиця"}:</TableCell>
                            <TableCell align="left"><strong>{data.shippingAddressStreet}</strong></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Прізвище отримувача:</TableCell>
                            <TableCell align="left"><strong>{shippingReceiverLastName}</strong></TableCell>
                            <TableCell align="right">Ім'я отримувача:</TableCell>
                            <TableCell align="left"><strong>{shippingReceiverFirstName}</strong></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Номер телефону отримувача:</TableCell>
                            <TableCell align="left"><strong>{shippingReceiverPhone}</strong></TableCell>
                            <TableCell align="right">Можна не телефонувати для підтвердження замовлення:</TableCell>
                            <TableCell align="left"><strong>{data.dontCallMe === false ? "Ні" : "Так"}</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* {rows.map((row) => (
                                <TableRow key={row.desc}>
                                    <TableCell>{row.desc}</TableCell>
                                    <TableCell align="right">11111</TableCell>
                                    <TableCell align="right">222222</TableCell>
                                    <TableCell align="right">333333333</TableCell>
                                </TableRow>
                            ))} */}
                        {/* <TableRow>
                                <TableCell rowSpan={4} colSpan={2} />
                                <TableCell >Валюта</TableCell>
                                <TableCell><strong>{data.curencyRate}</strong></TableCell>
                            </TableRow> */}
                        {/* <TableRow>
                                <TableCell>Курс</TableCell>
                                <TableCell><strong>{data.curency}</strong></TableCell>
                            </TableRow> */}
                        {/* <TableRow>
                                <TableCell >Знижка</TableCell>
                                <TableCell>{data.discountPercent}</TableCell>
                            </TableRow> */}
                        <TableRow>
                            <TableCell>Метод оплати:</TableCell>
                            <TableCell align="left" ><strong>{data.paymentMethod === "cash" ? "Готівка" : "Банк"}</strong></TableCell>
                            <TableCell align="right">Статус оплати:</TableCell>
                            <TableCell align="left"><strong>{data.paymentStatus === "order_payment_success" ? "Оплачено" : "Не оплачено"}</strong></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Сума:</TableCell>
                            <TableCell align="left"><strong>{data.sumOrdered} грн</strong></TableCell>
                            <TableCell align="right">Опис:</TableCell>
                            <TableCell align="left" colSpan={3}><div dangerouslySetInnerHTML={{ __html: data.description }}></div></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default ViewOrders;