import React, { useRef, useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AxiosInstance from '../Axios';
import CIcon from '@coreui/icons-react';
import { cilTrash } from '@coreui/icons';
import { confirmAlert } from 'react-confirm-alert';
// import async from 'react-select/dist/declarations/src/async';


const ViewProductOrders = (props) => {
    // console.log('ViewProductOrders props', props);

    const urlApi_1 = '/pre_order_products';
    const [products, setProduct] = useState({});

    // Текст для popup
    const popupTextSuccess = "Товар видалено";
    const popupTextWarning = "Товар не видалено";

    // Функція для запиту за товарами замовлення
    const getPreOrderProduct = async (id) => {
        try {
            const response = await AxiosInstance.get(`${urlApi_1}?preOrder=${id}`, { headers: { 'accept': 'application/json' } });
            // Заборонити повторне оновлення товарів
            props.setIsUpdateProductList(false);
            // Заповнити список з товарами
            setProduct(response.data);
        } catch (error) {
            consile.error("Помилка запиту за товарами до замовлення", error);
        };
    };

    // Функція для видалення товару
    const deletePreOrderProduct = async (id) => {
        try {
            const response = await AxiosInstance.delete(`${urlApi_1}/${id}`);
            // console.log('delete product response', response);
            // Після видалення товару потрібно оновити суму замовлення
            props.setIsUpdateSumOrdered(true);
            // Popup про успішні зміни
            props.toastifySuccess(popupTextSuccess);
            // Оновити список доданих товарів до замовлення
            getPreOrderProduct(props.data.id);
        } catch (error) {
            console.error("Помилка видалення продукту", error);
            props.toastifyWarning(popupTextWarning);
        };
    };

    // Зробити запит за товарами до замовлення
    useEffect(() => {
        if (props.data.id || props.isUpdateProductList) {
            getPreOrderProduct(props.data.id);
        };
    }, [props.data.id, props.isUpdateProductList]);

    // Функція для видалення товару з списку
    const deleteProduct = (id) => {
        confirmAlert({
            title: 'Ви впевнені що хочите видалити цей пункт ?',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Так',
                    onClick: () => {
                        deletePreOrderProduct(id);
                    }
                },
                {
                    label: 'Ні',
                    onClick: () => {
                        return;
                    }
                }
            ],
            overlayClassName: "myAllert" // App.css .myAllert "z-index: 9999999999"; щоб повідомлення було поверх модального вікна
        });
    };

    // Формування суми замовлення
    useEffect(() => {
        let sum = 0;
        if (products.length > 0) {
            products.map((product) => {
                let sumOrdered = (product.sumOrdered != undefined) ? product.sumOrdered : 0;
                sum += sumOrdered;
            });
        }
        // console.log("sum", sum);
        // Заповнити суму замовлення
        props.setSumOrdered(sum);
    }, [products]);

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center"><strong>#</strong></TableCell>
                            <TableCell align="center"><strong>Зображення</strong></TableCell>
                            <TableCell align="center"><strong>Назва</strong></TableCell>
                            <TableCell align="center"><strong>Ціна</strong></TableCell>
                            <TableCell align="center"><strong>Кількість</strong></TableCell>
                            <TableCell align="center"><strong>Одиниці Вим.</strong></TableCell>
                            <TableCell align="center"><strong>Сума</strong></TableCell>
                            <TableCell align="center"><strong>Знижка</strong></TableCell>
                            <TableCell align="center"><strong>Видалити</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            (products.length > 0) ?
                                products.map((value, key) => {
                                    // console.log('value', value);
                                    let i = key++ + 1;
                                    let imgUrl = (value.product.media != undefined && value.product.media.length > 0) ?
                                        `${process.env.REACT_APP_SERVER_URL}${(value.product.mainMedia != null && value.product.mainMedia != undefined) ?
                                            value.product.mainMedia.contentUrl
                                            :
                                            value.product.media[0].contentUrl}`
                                        :
                                        'product.jpg';
                                    // let measurementUnit = (value.measurementUnit != undefined) ? value.measurementUnit.short_name : value.product.measurement_unit.short_name;
                                    return (
                                        <TableRow key={value.id}>
                                            <TableCell align="center"><strong>{i}</strong></TableCell>
                                            <TableCell align="center">
                                                <Link to={`/products/read/${value.product.id}`} style={{ textDecoration: "none", color: "black" }}>
                                                    <strong><img src={imgUrl} alt="blog-image" style={{ width: "50px" }} /></strong>
                                                </Link>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Link to={`/products/read/${value.product.id}`} style={{ textDecoration: "none", color: "black" }}>
                                                    <strong>{value.product.name}</strong>
                                                </Link>
                                            </TableCell>
                                            <TableCell align="center"><strong>{value.priceForOne}</strong></TableCell>
                                            {/* <TableCell align="center"><strong>{value.product.price}</strong></TableCell> */}
                                            <TableCell align="center"><strong>{value.count}</strong></TableCell>
                                            {/* <TableCell align="center"><strong>{measurementUnit}</strong></TableCell> */}
                                            <TableCell align="center"><strong>шт.</strong></TableCell>
                                            <TableCell align="center"><strong>{value.sumOrdered}</strong></TableCell>
                                            <TableCell align="center"><strong>{value.discount}</strong></TableCell>
                                            <TableCell align="center">
                                                <CIcon icon={cilTrash} customClassName="nav-icon" height={18} onClick={() => deleteProduct(value.id)} style={{ cursor: "pointer" }} />
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                                // : ''
                                :
                                <TableRow></TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default ViewProductOrders;